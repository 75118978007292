@use 'styles/base/mixins';
@use 'styles/base/variables';

.wrapper {
  margin: variables.$spacing-gdds-section-mobile-large;
  @include mixins.bullet-points-spacing();

  @include mixins.screen-sm {
    margin: variables.$spacing-gdds-section-large;
  }

  &.greyBackground {
    background-color: variables.$gb_grey_050;
    margin: 0;
    padding: variables.$spacing-gdds-section-mobile-large;

    @include mixins.screen-sm {
      padding: variables.$spacing-gdds-section-large;
    }
  }

  &.whiteBackground {
    background-color: variables.$gb_white;
  }

  .text {
    margin-bottom: 1.5rem;

    p {
      font-size: 1rem;
    }
  }

  .contact {
    background: variables.$gb_grey_050;
    padding: 0.625rem 1.875rem;
    display: flex;
    flex-direction: column;

    @include mixins.screen-sm {
      flex-direction: row;
      padding: 3rem 3rem;
    }

    > div {
      flex: 0 0 50%;
      padding: 1.25rem 0;

      @include mixins.screen-sm {
        padding: 0 0.75rem;
      }
    }
  }
}

.contact {
  a {
    font-size: 1rem;
  }

  p {
    margin: 0;
    max-width: none;
    font-size: 1rem;

    .fixedWidth {
      display: inline-block;
      width: 4rem;
      flex: 0 0 auto;
    }

    .linkContainer a.link {
      padding: 0;
    }

    &.email {
      display: flex;
      align-items: center;
    }
  }
}
